/* global Gantt */

//import './dhtmlx-gantt/codebase/sources/dhtmlxgantt'
// import isEqual from/ 'lodash/isEqual'
import { equals, maxBy, minBy, reduce, set } from 'rambda'
import { debounce } from 'throttle-debounce'

class GanttComponent extends HTMLElement {
  constructor() {
    super()
    this._events = []
    this._filterEvent = null
    this._changedTasks = []
    this._relationsAdd = []
    this._relationsDelete = []
    this._initialParse = true
  }

  connectedCallback() {
    const script = document.createElement('script')
    script.src = '/dhtmlx-gantt-7x/dhtmlxgantt.js'
    script.async = true

    this.appendChild(script)

    this.container = document.createElement('section')
    this.container.appendChild(document.createElement('div')).id = 'gantt-container'
    this.appendChild(this.container)

    script.onload = () => {
      if (!document.getElementById('gantt-container')) return

      const script = document.createElement('script')
      script.src = '/dhtmlx-gantt/api.js'
      script.async = true

      document.body.appendChild(script)

      this.gantt = Gantt.getGanttInstance()

      this.setPlugins()
      this.setConfig()
      this.setZoom(this.zoom)

      this.initGantt()

      this.setLocale(this.getAttribute('lang'))
      this.enableAutoScheduling()

      this.addMarkers()

      this.setFilter()
      this.setIcons()
      this.gantt.parse({
        data: this.data,
        links: this.relations,
      })

      this.sort()

      this.props = {
        assigneeGroups: this.assigneeGroups,

        filteredData: this.filteredData,
        leftColumns: this.leftColumns,
        data: this.data,
        dataCollection: this.data.reduce((acc, item) => {
          acc[item.id] = { ...item }
          return acc
        }, {}),
        minAndMaxDate: this.minAndMaxDate,

        showCriticalPath: false,
      }

      // STYLING
      this.setTimelineStyles()
      this.skipWeekends()

      setInterval(this.checkForPropertyChange, 100)

      window._elmApp.ports.ganttEvents.subscribe(this.onEvent)
    }
  }

  disconnectedCallback() {
    if (!this.gantt) return

    this.gantt.clearAll()

    while (this._events.length) {
      this.gantt.detachEvent(this._events.pop())
    }

    this._changedTasks = []
    this._relationsAdd = []
    this._relationsDelete = []

    this.gantt.detachEvent(this._filterEvent)

    window._elmApp.ports.ganttEvents.unsubscribe(this.onEvent)
  }

  attachEvent(name, callback) {
    this._events.push(this.gantt.attachEvent(name, callback))
  }

  onEvent = ev => {
    if (ev == 'ExportAsPNG') {
      this.exportDiagram('png')
    }

    if (ev == 'ExportAsPDF') {
      this.exportDiagram('pdf')
    }

    if (ev == 'SaveGantt') {
      this._changedTasks = []
      this._relationsAdd = []
      this._relationsDelete = []
    }
  }

  exportDiagram = requested_type => {
    let type = requested_type == 'pdf' ? 'pdf' : 'png'
    const name = this.getAttribute('projectName')
    const fileName = `Gantt - ${name?.replace(/\./gi, '_')}.${type}`

    const tasks = this.gantt
      .getTaskByTime()
      .filter(task => this.customFilterFunction(task.id, task))
      .filter(task => !task.unscheduled)

    const getStartDate = task => task.start_date
    const getEndDate = task => task.end_date
    const baselineMinDate = new Date(1990, 1, 1)
    const baselineMaxDate = new Date(4000, 1, 1)

    const minTask = reduce(minBy(getStartDate), { start_date: baselineMaxDate }, tasks)
    const maxTask = reduce(maxBy(getEndDate), { end_date: baselineMinDate }, tasks)

    const minDate = minTask.start_date == baselineMaxDate ? new Date() : minTask.start_date
    const maxDate = maxTask.end_date == baselineMinDate ? new Date() : maxTask.end_date

    this.gantt.config.start_date = minDate
    this.gantt.config.end_date = maxDate

    let params = {
      name: fileName,
      header: `<div style="margin: 0.5em; font-size: 24px; line-height: 29px; font-weight: 700; color: #004080;">${name}</div>${styles}`,
      raw: true,
      locale: this.getAttribute('lang'),
      start: this.formatDate(minDate),
      end: this.formatDate(maxDate),
      server: '/dhtmlx-gantt/',
    }

    this.gantt.exportToPNG = gantt.exportToPNG
    this.gantt.exportToPDF = gantt.exportToPDF
    this.gantt._serialize_all = gantt._serialize_all
    this.gantt._send_to_export = gantt._send_to_export
    this.gantt._create_hidden_form = gantt._create_hidden_form
    this.gantt._serialize_html = gantt._serialize_html

    // config before export
    this.gantt.config.columns = this.enhanceColumns(this.leftColumns)
    //this.gantt.config.start_date = minDate
    //this.gantt.config.end_date = maxDate

    this.gantt.config.autosize = 'xy'
    this.gantt.render()

    if (type == 'png') {
      this.gantt.exportToPNG(params)
    } else {
      this.gantt.exportToPDF(params)
    }

    // revert config after export
    this.gantt.config.columns = this.enhanceColumns(this.leftColumns)
    this.gantt.config.autosize = 'y'
    this.gantt.config.start_date = new Date(this.minAndMaxDate.min_date)
    this.gantt.config.end_date = new Date(this.minAndMaxDate.max_date)
    this.gantt.render()

    const container = document.getElementById('gantt-container')
    if (!container) return
    container.style.height = 'auto'
    container.style.width = '100%'

    if (tasks.length > 0) {
      this.gantt.showTask(tasks[0].id)
    }
  }

  initGantt = () => {
    this.gantt.init('gantt-container')
    this.attachEvent('onBeforeLinkAdd', this.onBeforeLinkAdd)

    // -- data procesor
    this.attachEvent('onAfterTaskUpdate', this.onAfterTaskUpdate)
    this.attachEvent('onAfterLinkAdd', this.onAfterLinkAdd)
    this.attachEvent('onAfterLinkDelete', this.onAfterLinkDelete)

    // this.attachEvent('onTaskClick', this.onTaskClick)
    this.attachEvent('onTaskDblClick', this.onTaskDblClick)

    this.attachEvent('onTaskDrag', this.onTaskDrag)

    this.attachEvent('onbeforeLightbox', this.onbeforeLightbox)
  }

  onbeforeLightbox = id => {
    return false
    //@TODO -Tasks from gantt- this.dispatchEvent(new CustomEvent('gantt-create-task', { detail: this.gantt.getTask(id).parent })  )
  }

  renderGantt = () => {
    this.gantt.render()
  }

  calculateSummaryProgress(task) {
    if (task.type != this.gantt.config.types.project) {
      return task.progress
    }

    let totalToDo = 0
    let totalDone = 0
    this.gantt.eachTask(child => {
      if (child.type != this.gantt.config.types.project) {
        totalToDo += child.duration
        totalDone += (child.progress || 0) * child.duration
      }
    }, task.id)
    if (!totalToDo) {
      return 0
    } else {
      return totalDone / totalToDo
    }
  }

  refreshSummaryProgress(id, submit) {
    if (!this.gantt.isTaskExists(id)) {
      return
    }
    let task = this.gantt.getTask(id)
    let newProgress = this.calculateSummaryProgress(task)

    if (!task.isProject && this._changedTasks && !this._changedTasks.includes(id)) {
      this._changedTasks.push(id)
    }

    if (newProgress !== task.progress) {
      task.progress = newProgress

      if (!submit) {
        this.gantt.refreshTask(id)
      } else {
        this.gantt.updateTask(id)
      }
    }

    if (!submit && this.gantt.getParent(id) !== this.gantt.config.root_id) {
      this.refreshSummaryProgress(this.gantt.getParent(id), submit)
    }
  }

  onBeforeLinkAdd = (id, link) => {
    if (link.type != '0') {
      return false
    }

    return true
  }

  onAfterTaskUpdate = id => {
    if (!this.props) {
      return
    }

    const task = this.gantt.getTask(id)
    const sourceTask = this.props.dataCollection[id]

    if (!task.$virtual && task.startAndDueDateLocked) {
      task.constraint_type = 'mso'
      task.constraint_date = task.start_date
    }

    if (this._changedTasks && !this._changedTasks.includes(id)) {
      if (
        this.formatDate(task.start_date) != this.formatDate(sourceTask.start_date) ||
        task.duration != sourceTask.duration ||
        task.progress != sourceTask.progress
      ) {
        this._changedTasks.push(id)
      }

      // for auto scheduling
      //if (
      //  this.formatDate(task.start_date) == this.formatDate(sourceTask.start_date) &&
      //  task.duration == sourceTask.duration &&
      //  task.progress == sourceTask.progress
      //) {
      //  this._changedTasks = this._changedTasks.filter(item => item !== id)
      //}
    }

    this.dispatchChange()
  }

  onAfterLinkAdd = (id, link) => {
    if (!this._relationsAdd.includes(link)) {
      this._relationsAdd.push(link)
    }

    this.dispatchChange()
  }

  onAfterLinkDelete = (id, link) => {
    const notSavedLink = this._relationsAdd.find(item => item.id == link.id)

    if (notSavedLink) {
      this._relationsAdd = this._relationsAdd.filter(item => item.id !== link.id)
    } else {
      this._relationsDelete.push(link)
    }

    this.dispatchChange()
  }

  onTaskClick = (id, { target }) => {
    return false
  }

  onTaskDblClick = (id, { target }) => {
    if (target) {
      if (
        target.className == 'gantt-left-column-subject' ||
        target.className == 'gantt-left-column-parent-subject'
      ) {
        this.dispatchEvent(new CustomEvent('gantt-task-selected', { detail: { id } }))
      } else {
        return true
      }
    } else {
      return true
    }
  }
  onTaskDrag = (id, mode, task, original) => {
    if (task.$virtual) {
      return false
    }

    const parentId = this.gantt.getParent(id)
    if (!this.gantt.isTaskExists(parentId)) {
      return false
    }

    const parent = this.gantt.isTaskExists(parentId) ? this.gantt.getTask(parentId) : null

    if (parent.$virtual) {
      return false
    }

    this.refreshSummaryProgress(parentId, false)

    // limit drag n drop
    if (parent.startAndDueDateLocked) {
      const modes = this.gantt.config.drag_mode
      const leftLimit = parent.start_date
      const rightLimit = parent.end_date

      if (mode == modes.move || mode == modes.resize) {
        var diff = original.duration * (1000 * 60 * 60 * 24)

        if (+task.end_date > +rightLimit) {
          task.end_date = new Date(rightLimit)

          if (mode == modes.move) {
            task.start_date = new Date(task.end_date - diff)
          }
        }

        if (+task.start_date < +leftLimit) {
          task.start_date = new Date(leftLimit)

          if (mode == modes.move) {
            task.end_date = new Date(+task.start_date + diff)
          }
        }
      }
    }
  }

  debouncedAutoSchedule = debounce(250, () => {
    this.gantt.autoSchedule()
  })

  dispatchChange = debounce(250, () => {
    this.dispatchChangeCall()
  })

  dispatchChangeCall = () => {
    const tasks = this.gantt.getTaskByTime()

    this.gantt.autoSchedule()

    const changedData = tasks
      .filter(
        task =>
          this._changedTasks.includes(task.id) &&
          !task.$virtual &&
          !task.isProject &&
          task.start_date &&
          task.end_date &&
          !task.unscheduled
      )
      .map(task => {
        return {
          ...task,
          start_date: task.start_date ? task.start_date.toISOString() : null,
          end_date: task.end_date ? task.end_date.toISOString() : null,
        }
      })

    this.dispatchEvent(
      new CustomEvent('gantt-update', {
        detail: {
          data: changedData,
          linksAdd: this._relationsAdd,
          linksDelete: this._relationsDelete,
        },
      })
    )
  }

  // const updatedTasks = tasks
  //   .filter(
  //     task =>
  //       !task.$virtual && !task.isProject && task.start_date && task.end_date && !task.unscheduled
  //   )
  //   .map(task => {
  //     return {
  //       ...task,
  //       start_date: task.start_date ? task.start_date.toISOString() : null,
  //       end_date: task.end_date ? task.end_date.toISOString() : null,
  //     }
  //   })

  setPlugins = () => {
    this.gantt.plugins({
      auto_scheduling: true,
      marker: true,
      drag_timeline: true,
      grouping: true,
      critical_path: true,
      multiselect: true,
    })
  }

  sort = (param = 'end_date') => {
    this.gantt.sort(param, false)
  }

  addMarkers = () => {
    const markers = document.querySelectorAll('.gantt_marker_area')[1]
    const tasksBars = document.querySelectorAll('.gantt_bars_area')
    const dataArea = document.querySelectorAll('.gantt_data_area')

    dataArea[0].insertBefore(markers, tasksBars[0])

    const plannedStartDate = this.getAttribute('plannedStartDate')
    const plannedDueDate = this.getAttribute('plannedDueDate')

    this.todayMarker = this.gantt.addMarker({
      start_date: new Date(),
      css: 'today',
      title: this.getAttribute('todayMarkerTranslation'),
    })

    if (plannedStartDate) {
      this.plannedStartDateMarker = this.gantt.addMarker({
        start_date: new Date(plannedStartDate),
        css: 'planned-start-date',
        title: this.getAttribute('plannedStartDateMarkerTranslation'),
      })
    }

    if (plannedDueDate) {
      this.plannedDueDateMarker = this.gantt.addMarker({
        start_date: new Date(plannedDueDate),
        css: 'planned-due-date',
        title: this.getAttribute('plannedDueDateMarkerTranslation'),
      })
    }
  }

  skipWeekends = () => {
    const skipWeekends = this.getAttribute('skipWeekends') === 'true'
    this.gantt.config.work_time = skipWeekends
  }

  enableAutoScheduling = () => {
    this.gantt.config.auto_scheduling_strict = true
    this.gantt.config.auto_scheduling_compatibility = true
    this.gantt.config.auto_scheduling_descendant_links = false
    this.gantt.config.auto_scheduling = true
    this.gantt.config.auto_scheduling_initial = true
  }

  setZoom = (zoom, forceRender = false) => {
    const dateToQuarterLabel = date => {
      let dateToStr, endDate
      dateToStr = this.gantt.date.date_to_str('%m')
      endDate = this.gantt.date.add(date, 2, 'month')

      return 'Q' + ((parseInt(dateToStr(date).toString()) - 1) / 3 + 1)
    }

    const highlightWeekends = date => {
      if (['detailed', 'days'].includes(zoom) && [0, 6].includes(date.getDay())) {
        return 'gantt-column-weekend'
      }
      return ''
    }

    const { days, detailed, months, quartals, weeks } = this.translatedScalesProperty
    const setTranslatedFormat = (unit, scales) => {
      return scales.find(item => item.unit == unit).format
    }

    // https://docs.dhtmlx.com/gantt/desktop__date_format.html
    const scales = {
      detailed: [
        { unit: 'week', step: 1, format: setTranslatedFormat('week', detailed) },
        //prettier-ignore
        { unit: 'day', step: 1, format: setTranslatedFormat('day', detailed), css: highlightWeekends, },
      ],
      days: [
        { unit: 'month', step: 1, format: setTranslatedFormat('month', days) },
        { unit: 'day', step: 1, format: setTranslatedFormat('day', days), css: highlightWeekends },
      ],
      weeks: [
        { unit: 'month', step: 1, format: setTranslatedFormat('month', weeks) },
        { unit: 'week', step: 1, format: setTranslatedFormat('week', weeks) },
      ],
      months: [
        { unit: 'year', step: 1, format: setTranslatedFormat('year', months) },
        { unit: 'month', step: 1, format: setTranslatedFormat('month', months) },
      ],
      quartals: [
        { unit: 'year', step: 1, format: setTranslatedFormat('year', quartals) },
        { unit: 'quarter', step: 1, template: dateToQuarterLabel },
      ],
    }

    const columnWidth = {
      detailed: 70,
      days: 18,
      weeks: 50,
      months: 70,
      quartals: 70,
    }

    this.gantt.config.scales = scales[zoom]
    this.gantt.config.min_column_width = columnWidth[zoom]

    this.gantt.config.round_dnd_dates = ['detailed', 'days'].includes(zoom)
    this.gantt.config.time_step = 1440

    const highlightWeekendsCell = (task, date) => {
      if (['detailed', 'days'].includes(zoom) && [0, 6].includes(date.getDay())) {
        return 'gantt-timeline-weekend'
      }
    }

    this.gantt.templates.timeline_cell_class = highlightWeekendsCell

    if (forceRender) {
      this.renderGantt()
    }
  }

  setLocale = locale => {
    this.gantt.i18n.setLocale(locale)
    this.gantt.i18n.setLocale(this.locale)
  }

  setConfig = () => {
    this.gantt.config.columns = this.enhanceColumns(this.leftColumns)

    this.gantt.getGridColumn('projectName').hide = this.isRoot || this.assigneeGroups === null

    this.gantt.config.start_date = new Date(this.minAndMaxDate.min_date)
    this.gantt.config.end_date = new Date(this.minAndMaxDate.max_date)

    // this.gantt.config.date_grid = '%j. %n. %Y'
    this.gantt.config.date_grid = this.getAttribute('configDateGrid')
    this.gantt.templates.grid_date_format = date => {
      return this.gantt.date.date_to_str(this.gantt.config.date_grid)(date)
    }
    this.gantt.templates.format_date = date => date.toISOString()

    this.gantt.config.show_unscheduled = true

    // left column
    this.gantt.config.autofit = true
    this.gantt.ext.inlineEditors.attachEvent('onBeforeEditStart', state => {
      const task = this.gantt.getTask(state.id)

      return !(task.type == 'project')
    })

    // dates area
    this.gantt.config.min_duration = 1 * 24 * 60 * 60 * 1000
    this.gantt.config.fit_tasks = true
    this.gantt.config.drag_project = true
    this.gantt.config.autoscroll = true
    this.gantt.config.autoscroll_speed = 50

    //this.gantt.config.date_grid = "%Y-%m-%d %H:%i"

    // policy
    this.gantt.config.readonly = this.readOnly

    this.gantt.groupBy(this.assigneeGroups ? this.assigneeGroups : false)
    this.sort()

    this.attachEvent('onParse', () => {
      if (this._initialParse) {
        setTimeout(() => {
          this.gantt.showDate(new Date())
        }, 50)
      }

      this._initialParse = false
    })
  }

  checkForPropertyChange = () => {
    if (!equals(this.props.assigneeGroups, this.assigneeGroups)) {
      this.gantt.groupBy(this.assigneeGroups === null ? false : this.assigneeGroups)
      this.sort()

      this.props.assigneeGroups = this.assigneeGroups

      this.gantt.getGridColumn('projectName').hide = this.isRoot || this.assigneeGroups === null

      this.renderGantt()
    }

    if (this.props.filteredData !== this.filteredData) {
      this.setFilter()
      this.props.filteredData = this.filteredData

      this.renderGantt()
    }

    if (!equals(this.props.relations, this.relations)) {
      if (this.props.relations) {
        this.gantt.clearAll()
        this.gantt.parse({
          data: this.data,
          links: this.relations,
        })

        this.addMarkers()
        this.sort()
        this.renderGantt()
      }

      this.props.relations = this.relations
    }

    if (!equals(this.props.leftColumns, this.leftColumns)) {
      this.gantt.config.columns = this.leftColumns

      this.props.leftColumns = this.leftColumns

      this.renderGantt()
    }

    if (this.props.zoom !== this.zoom) {
      this.setZoom(this.zoom, true)

      this.zoom = this.props.zoom
    }

    if (!equals(this.props.minAndMaxDate, this.minAndMaxDate)) {
      this.gantt.config.start_date = new Date(this.minAndMaxDate.min_date)
      this.gantt.config.end_date = new Date(this.minAndMaxDate.max_date)

      this.props.minAndMaxDate = this.minAndMaxDate

      this.renderGantt()
    }

    if (!equals(this.props.showCriticalPath, this.showCriticalPath)) {
      this.gantt.config.highlight_critical_path = this.showCriticalPath

      this.props.showCriticalPath = this.showCriticalPath

      this.renderGantt()
    }
  }

  formatEndDate = task => {
    const date = this.gantt.calculateEndDate({
      start_date: task.end_date,
      duration: -1,
      task: task,
    })
    return this.gantt.templates.date_grid(date, task)
  }

  formatLabel = task => {
    const setClassname = () => {
      if (task.isProject || task.$virtual) {
        return 'project-name'
      } else if (task.type == 'project') {
        return 'parent-subject'
      } else {
        return 'subject'
      }
    }

    return `<span class="gantt-left-column-${setClassname()}">${task.text}</span>`
  }

  enhanceColumns = columns => {
    const newColumns = columns.map(column => {
      if (column.type == 'name_column') {
        return {
          ...column,
          template: this.formatLabel,
        }
      } else if (column.type == 'end_date') {
        return {
          ...column,
          template: this.formatEndDate,
          editor: { type: 'date', map_to: 'end_date' },
        }
      } else return column
    })

    return newColumns
  }

  formatDate = date => {
    const d = new Date(date)
    const day = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const year = d.getFullYear()
    return `${day}-${month}-${year}`
  }

  setIcons = () => {
    this.gantt.templates.grid_folder = function (item) {
      const removeIconIf = item.type == 'project' && !item.isProject ? '-no-parent-icon' : ''

      return (
        "<div class='gantt_tree_icon gantt_folder_" +
        (item.$open ? 'open' : 'closed') +
        `${removeIconIf}'></div>`
      )
    }

    this.gantt.templates.grid_file = task => {
      if (this._changedTasks.includes(task.id)) {
        return `<div class='gantt_tree_icon gantt_file gantt_file_has_changes'></div>`
      } else {
        return "<div class='gantt_tree_icon gantt_file'></div>"
      }
    }
    this.gantt.templates.grid_folder = task => {
      if (this._changedTasks.includes(task.id)) {
        return `<div class='gantt_tree_icon gantt_file gantt_file_has_changes'></div>`
      } else {
        return "<div class='gantt_tree_icon gantt_file'></div>"
      }
    }
  }
  customFilterFunction = (id, task) => {
    // task.$virtual is for grouping

    return id in this.filteredData || task.$virtual
  }

  setFilter = () => {
    this._filterEvent = this.gantt.attachEvent('onBeforeTaskDisplay', this.customFilterFunction)
  }

  setTimelineStyles = () => {
    this.gantt.templates.task_text = (start, end, task) => {
      const icon =
        !task.$virtual && task.startAndDueDateLocked
          ? '<span class="gantt_task_lock"><img alt="padlock-icon" src="/assets/gantt/padlock-locked.png" /></span>'
          : ''

      return `${task.text}${icon}`
    }
    this.gantt.templates.task_class = (start, end, task) => {
      const now = new Date()
      const isOverdue = end < now
      const isSolved = task.isSolved
      const isClosed = task.isClosed ? task.isClosed : false

      if (task.isProject || task.$virtual) {
        return 'gantt-bar-project'
      }

      if (task.type == 'project' && isClosed) {
        return 'gantt-bar-parent-task gantt-bar-task-closed'
      }

      if (task.type == 'project' && isSolved && !isClosed) {
        return 'gantt-bar-parent-task gantt-bar-task-solved'
      }

      if (task.type == 'project' && isOverdue && !isClosed && !isSolved) {
        return 'gantt-bar-parent-task gantt-bar-task-overdue'
      }

      if (task.type == 'project' && !isOverdue && !isClosed && !isSolved) {
        return 'gantt-bar-parent-task'
      }

      if (isClosed) {
        return 'gantt-bar-task gantt-bar-task-closed'
      }

      if (isSolved && !isClosed) {
        return 'gantt-bar-task gantt-bar-task-solved'
      }

      if (isOverdue && !isClosed && !isSolved) {
        return 'gantt-bar-task gantt-bar-task-overdue'
      }

      return 'gantt-bar-task'
    }
  }
}
//marker export styles - not used - removed
// .gantt_marker.today {
//   background: #9F40FF;
// }

// .gantt_marker.planned-start-date {
//   #99E58A;
// }

// .gantt_marker.planned-due-date {
//   #FF9999;
// }

customElements.define('gantt-container', GanttComponent)

const styles = `
      <style>
      .gantt_task .gantt_task_scale .gantt_scale_cell,
      .gantt_cell,
      .gantt_grid_head_cell {
        color : #004080;
      }

      .gantt_tree_icon.gantt_close {
        background-image: none;
      }

      .gantt_tree_icon.gantt_open {
        @apply rotate-[-90deg];
        background-image: none;
      }

      .gantt_tree_icon.gantt_folder_open,
      .gantt_tree_icon.gantt_folder_closed {
        background-image: none;
      }

      .gantt_grid_head_cell.gantt_grid_head_add {
        display:none
      }

      .gantt-left-column-project-name {
        font-weight: 700;
        font-size: 14px;
        color: #004080;
      }

      .gantt-left-column-subject {
        font-size: 14px;
        color: #676D73;
      }

      .gantt-left-column-parent-subject {
        font-size: 14px;
        color: #14171A;
      }

      .gantt_grid_editor_placeholder input {
        padding: 0 10px 0 10px;
      }

      .gantt_marker {
        background: rgba(0, 0, 0, 0);
      }

      .gantt_tree_icon.gantt_file {
        background-image: none;
      }

      .gantt_tree_content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .gantt-column-weekend {
        background: #F0F2F5;
        border-bottom: 1px solid #C3CED9;
      }

      .gantt-timeline-weekend {
        background: #F0F2F5;
        border-right: 1px solid #E8EDF2;
        border-left: 1px solid #E8EDF2;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-project.gantt_project {
        background: #DCE0E5;
        outline: unset;
        border: 1px solid #ACB6BF;
        box-shadow: none;
        color: #004080;
        border-radius: 4px;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-project.gantt_project.gantt_critical_task {
        background: #F23D3D;
        border: 1px solid #B20000;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-project.gantt_project.gantt_critical_task .gantt_task_progress_wrapper .gantt_task_progress {
        background: #B20000;
      }

      .gantt-bar-project .gantt_link_control,
      .gantt_task_progress_drag {
        display:none;
      }

      .gantt-bar-project .gantt_task_progress_wrapper .gantt_task_progress {
        background: #C3CED9;
      }

      .gantt-bar-parent-task .gantt_task_progress_drag {
        display:none;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task {
        background: #F0F2F5;
        border: 1px solid #ACB6BF;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task .gantt_task_content {
        color: #004080;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-closed .gantt_task_progress_wrapper .gantt_task_progress {
        display:none;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-solved .gantt_task_progress_wrapper .gantt_task_progress {
        display:none;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-closed {
        background: #676D73;
        border: 1px solid #676D73;
      }

      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-closed .gantt_task_content,
      .gantt_bars_area .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-solved .gantt_task_content {
        color: #FFFFFF !important;
      }
      .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-solved {
        background: #4DCC33;
        border: 1px solid #1A9900;
        color: #FFFFFF;
      }

      .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-overdue {
        background: #FFE6CC;
        border: 1px solid #FF9F40;
      }

      .gantt_task_line.gantt-bar-parent-task.gantt-bar-task-overdue .gantt_task_progress_wrapper .gantt_task_progress {
        background: #FF9F40;
      }

      .gantt_task_line.gantt-bar-parent-task.gantt_critical_task {
        background: #FF9999;
        border: 1px solid #FF9999;
      }

      .gantt_task_line.gantt-bar-parent-task.gantt_critical_task .gantt_task_progress_wrapper .gantt_task_progress {
        background: #F23D3D;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task {
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #ACB6BF;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task .gantt_task_content {
        color: #004080;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task .gantt_task_progress_wrapper .gantt_task_progress {
        background: #D5FFCC;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task .gantt_task_content .gantt_task_lock {
          display: none;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-closed {
        background: #676D73;
        border: 1px solid #676D73;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-closed .gantt_task_progress_wrapper .gantt_task_progress {
        background: #676D73;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-closed .gantt_task_content,
      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-solved .gantt_task_content {
        color : #FFFFFF;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-solved {
        background: #4DCC33;
        border: 1px solid #1A9900;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-solved .gantt_task_progress_wrapper .gantt_task_progress {
        background: #4DCC33;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-overdue {
        background: #FFE6CC;
        border: 1px solid #FF9F40;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt-bar-task-overdue .gantt_task_progress_wrapper .gantt_task_progress {
        background: #FF9F40;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt_critical_task {
        background: #FF9999;
        border: 1px solid #FF9999;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt_critical_task .gantt_task_content {
        color: #000000;
      }

      .gantt_bars_area .gantt_task_line.gantt_bar_task.gantt_critical_task .gantt_task_progress_wrapper .gantt_task_progress {
        background: #F23D3D;
      }

      .gantt_layout_content .gantt_task .gantt_data_area {
        padding-bottom: 20px;
      }
      </style>
    `
