import '@vaadin/date-picker'
import { throttle } from 'throttle-debounce'

class ProjectlyDatepicker extends HTMLElement {
  static get observedAttributes() {
    return ['max', 'min', 'value']
  }

  connectedCallback() {
    this.picker = document.createElement('vaadin-date-picker')

    this.appendChild(this.picker)

    this.picker.i18n = datePickerI18n(this.translated)
    this.picker.clearButtonVisible = this.clearButtonVisible
    this.picker.opened = this.opened
    this.picker.placeholder = this.placeholder

    this.setMax(this.getAttribute('max'))
    this.setMin(this.getAttribute('min'))
    this.setValue(this.getAttribute('value'))

    // set some style on body of html add class of name .skipWeekends

    getLangFromCookie()

    this.picker.addEventListener('value-changed', (event: CustomEvent) => {
      // if removed it acts better, since it is dropping the last used value

      const picker: EventTarget = event.target
      const validDate = !picker.__data.invalid
      if (validDate) {
        this.dispatchChange(event)
      }
    })

    this.picker.querySelector('input').addEventListener('input', (ev: Event) => {
      ev.stopPropagation()
    })
  }

  setMax(value: string) {
    if (!value) return

    if (value == 'noMaxDate') {
      this.picker.removeAttribute('max')
      return
    } else {
      this.setPickerAttribute('max', value)
    }

    this.setPickerAttribute('max', value)
  }

  setMin(value: string) {
    if (!value) return

    if (value == 'noMinDate') {
      this.picker.removeAttribute('min')
      return
    } else {
      this.setPickerAttribute('min', value)
    }
  }

  setValue(value: string) {
    this.setPickerAttribute('value', value)
  }

  setPickerAttribute(name: string, value: string) {
    if (value === '') {
      this.picker.setAttribute(name, value)
      return
    }

    let date = new Date(value)

    this.picker.setAttribute(
      name,
      sprintf('%s-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate())
    )
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string) {
    if (!this.picker) return

    if (name === 'min' && oldValue !== newValue) {
      this.setMin(newValue)
    }

    if (name === 'max' && oldValue !== newValue) {
      this.setMax(newValue)
    }

    if (name === 'value' && oldValue !== newValue) {
      this.setValue(newValue)
    }
  }

  dispatchChange = throttle(150, (ev: Event) => {
    let date = ''

    if (ev.detail.value !== '') {
      const mode = this.getAttribute('mode')

      if (mode === 'date') {
        date = new Date(ev.detail.value).toISOString()
      } else {
        date = new Date(ev.detail.value + 'T00:00:00').toISOString()
      }
    }

    this.dispatchEvent(new CustomEvent('date-changed', { detail: { value: date } }))
  })
}

customElements.define('prj-datepicker', ProjectlyDatepicker)

function sprintf(str) {
  var args = arguments,
    i = 1

  return str.replace(/%(s|d|0\d+d)/g, function (x, type) {
    var value = args[i++]
    switch (type) {
      case 's':
        return value
      case 'd':
        return parseInt(value, 10)
      default:
        value = String(parseInt(value, 10))
        var n = Number(type.slice(1, -1))
        return '0'.repeat(n).slice(value.length) + value
    }
  })
}

function datePickerI18n({
  monthNames,
  weekdays,
  weekdaysShort,
  week,
  today,
  calendar,
  cancel,
}: {
  monthNames: string[]
  weekdays: string[]
  weekdaysShort: string[]
  week: string
  today: string
  calendar: string
  cancel: string
}) {
  return {
    monthNames,
    weekdays,
    weekdaysShort,
    week,
    today,
    calendar,
    cancel,
    firstDayOfWeek: 1,
    formatDate: (date: { day: number; month: number; year: number }) =>
      `${date.day}. ${date.month + 1}. ${date.year}`,
    formatTitle: (monthName: string, fullYear: string) => `${monthName} ${fullYear}`,
    parseDate: (text: string) => {
      const parts = text.split('.')

      return { day: parseInt(parts[0]), month: parseInt(parts[1]) - 1, year: parseInt(parts[2]) }
    },
  }
}

function getLangFromCookie() {
  const b = document.cookie.match('(^|[^;]+)\\s*lang\\s*=\\s*([^;]+)')

  return b ? b.pop() : 'cs'
}
