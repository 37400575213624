import { Crisp } from 'crisp-sdk-web'

Crisp.configure('09a603bb-0882-4dcf-88c0-fcc9e505f740', {
  autoload: false,
  safeMode: true,
  sessionMerge: true,
})

Crisp.setHideOnMobile(true)

class CrispChat extends HTMLElement {
  static get observedAttributes() {
    return ['data-company', 'data-user-mail', 'data-user-nickname', 'data-session-data']
  }

  connectedCallback() {
    this.startChat()
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'data-company') {
      Crisp.user.setCompany(newValue)
    } else if (name === 'data-user-mail') {
      Crisp.user.setEmail(newValue)
    } else if (name === 'data-user-nickname') {
      Crisp.user.setNickname(newValue)
    } else if (name === 'data-session-data') {
      Crisp.session.setData(JSON.parse(newValue) || {})
    }
  }

  startChat() {
    Crisp.load()

    setTimeout(() => {
      window._elmApp.ports.events.subscribe(ev => {
        if (ev === 'SignedOut') {
          Crisp.session.reset()
        }
        if (ev === 'ShowCrisp') {
          Crisp.setHideOnMobile(false)
          Crisp.chat.open()
        }
      })
    }, 500)
  }
}
Crisp.chat.onChatClosed(() => {
  // Executed once the chat was closed
  Crisp.setHideOnMobile(true)
})

customElements.define('crisp-chat', CrispChat)
