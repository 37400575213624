/*
  This component is responsible for sending analytics data to the server and also to the Google Analytics.

  It also handles the Google Tag Manager initialization.

  Not every event is sent to the server, only the ones that are important for the business.
*/

window.dataLayer = window.dataLayer || []

function gtag() {
  window.dataLayer.push(arguments)
}

class Measurement extends HTMLElement {
  constructor() {
    super()

    /**
     * @type {{ admin: boolean; tariff: string; tenant: string; userId: any; } | null}
     */
    this.user = this.user || null
  }

  connectedCallback() {
    this.initGTag()
  }

  initGTag() {
    if (
      !this.getAttribute('data-gtm-id') ||
      !['_localhost', 'app.projektove.cz'].includes(window.location.hostname)
    ) {
      return
    }

    gtag('consent', 'default', { ad_storage: 'granted', analytics_storage: 'granted' })
    gtag('js', new Date())
    gtag('config', this.getAttribute('data-gtm-id'))

    const gtagScript = document.createElement('script')

    gtagScript.async = true
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.getAttribute(
      'data-gtm-id'
    )}`
    document.head.appendChild(gtagScript)

    setTimeout(() => {
      window._elmApp.ports.measureEvent.subscribe(ev => {
        const [name, data] = ev

        if (name === null) {
          window.dataLayer.push(data)
        } else {
          gtag('event', name, data)
        }
      })
    }, 250)

    setTimeout(() => {
      window._elmApp.ports.setGtagUser.subscribe(user => {
        this.user = user

        if (!this.user) return
        const { admin, tariff, tenant, userId } = this.user

        gtag('set', 'user_id', userId)
        gtag('set', 'user_properties', {
          admin,
          tariff,
          tenant,
        })
      })
    }, 250)
  }
}

customElements.define('app-measurement', Measurement)
