export const storageHandlerVersion = 3

export function getStorage() {
  let storage = localStorage.getItem('storage')

  if (storage) {
    storage = JSON.parse(storage)

    if (storage._ver != storageHandlerVersion) {
      return migrateStorage(storage)
    } else {
      return storage
    }
  }

  return {}
}

export function setStorage(storage) {
  localStorage.setItem('storage', JSON.stringify(storage))
}

const storageMigrations = {
  0: storage => {
    const user = storage.user

    return {
      _ver: 1,
      user: null,
    }
  },
  1: storage => {
    const user = storage.user

    return {
      _ver: 2,
      user: { ...user, id: `User:${user.id}`, admin: false },
    }
  },
  2: storage => {
    if (storage.user) {
      localStorage.setItem('user.jwt', JSON.stringify(storage.user.jwt))
    }

    delete storage.user
    storage._ver = 3

    return storage
  },
}

function migrateStorage(storage) {
  const ver = storage._ver || 0

  for (const v of range(storageHandlerVersion - ver, ver)) {
    storage = storageMigrations[v](storage)
  }

  localStorage.setItem('storage', JSON.stringify(storage))

  return storage
}

function range(size, startAt) {
  return [...Array(size).keys()].map(i => i + startAt)
}
