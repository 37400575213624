class Budget extends HTMLElement {
  static get observedAttributes() {
    return ['projectId', 'key', 'tenant']
  }

  connectedCallback() {
    const key = this.getAttribute('key')
    const tenant = this.getAttribute('tenant')
    const projectId = this.getAttribute('projectId')

    if (projectId || key || tenant) {
      const iframe = document.createElement('iframe')
      iframe.src = `/v1/projects/${projectId}/budget_entries/v2?tenant=${tenant}&key=${key}`
      iframe.style.height = '100%'
      iframe.style.width = '100%'
      iframe.style.border = 'none'

      iframe.addEventListener('load', () => this.sendInitData(iframe))
      this.appendChild(iframe)

      this.container = document.createElement('div')
      this.container.id = 'budget-container'
      this.appendChild(this.container)

      window.addEventListener('message', this.handleMessage.bind(this))
    }
  }

  sendInitData(iframe) {
    const initData = { loaded: true }

    const origin = this.getOrigin(iframe.src)
    iframe.contentWindow.postMessage({ initData }, origin)
  }

  getOrigin(url) {
    const pathAr = url.split('/')
    const protocol = pathAr[0]
    const host = pathAr[2]
    return protocol + '//' + host
  }

  handleMessage(event) {
    const origin = this.getOrigin(window.location.origin)

    if (event.origin !== origin) {
      return
    }

    if (event.data.openTask) {
      this.dispatchEvent(new CustomEvent('open-task', { detail: event.data.openTask }))
    }
    if (event.data.openProject) {
      this.dispatchEvent(new CustomEvent('open-project', { detail: event.data.openProject }))
    }
  }

  disconnectedCallback() {
    window.removeEventListener('message', this.handleMessage)
  }
}

customElements.define('budget-container', Budget)
