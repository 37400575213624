function LocalStorage(PortFunnel) {
  let moduleName = 'LocalStorage'
  let storage = localStorage
  let sub

  function init() {
    if (!PortFunnel || !PortFunnel.sub || !PortFunnel.modules) {
      // Loop until PortFunnel.js has initialized itself.
      setTimeout(init, 10)
      return
    }

    // sub = PortFunnel.sub;
    PortFunnel.modules[moduleName] = { cmd: dispatcher }

    // Let the Elm code know we've started
    PortFunnel.sub.send({ module: moduleName, tag: 'startup', args: null })
  }

  init()

  function dispatcher(tag, args) {
    if (tag == 'get') {
      let label = args.label
      let key = args.key
      let val = null

      try {
        val = JSON.parse(storage.getItem(key))
      } catch (e) {}

      // console.log('JS: localStorage', key, val)

      return {
        module: moduleName,
        tag: 'got',
        args: { label: label, key: key, value: val },
      }
    } else if (tag == 'put') {
      let key = args.key
      let json = args.value

      if (typeof key == 'string') {
        if (json === null) {
          storage.removeItem(key)
        } else {
          let str = JSON.stringify(json)

          if (typeof str == 'string') {
            storage.setItem(key, str)
          }
        }
      }
    } else if (tag == 'listkeys') {
      let label = args.label
      let prefix = args.prefix
      let keys = []

      if (typeof prefix == 'string') {
        let cnt = storage.length

        for (let i = 0; i < cnt; i++) {
          let key = storage.key(i)

          if (key && key.startsWith(prefix)) {
            keys.push(key)
          }
        }
      }
      return {
        module: moduleName,
        tag: 'keys',
        args: { label: label, prefix: prefix, keys: keys },
      }
    } else if (tag == 'clear') {
      let prefix = args

      if (prefix) {
        if (typeof prefix == 'string') {
          let cnt = storage.length

          for (let i = cnt - 1; i >= 0; --i) {
            let key = storage.key(i)

            if (key && key.startsWith(prefix)) {
              storage.removeItem(key)
            }
          }
        }
      } else {
        storage.clear()
      }
    }
  }
}

export default LocalStorage
