class GooglePickerComponent extends HTMLElement {
  connectedCallback() {
    window._elmApp.ports.googlePickerApiEvents.subscribe(this.onEvent)
  }

  init() {
    // https://developers.google.com/drive/picker/reference

    const SCOPES = 'https://www.googleapis.com/auth/drive.file'

    const API_KEY = 'AIzaSyBOAzAYx8pX4sHKG7rl9QbwYwveb8OcODk'
    const CLIENT_ID = '1062228752473-e32ib2nlfkjlvl12c9pdmmsnimug5m6i.apps.googleusercontent.com'

    let tokenClient

    let accessToken = localStorage.getItem('google-drive-access-token') || null
    let pickerInited = false
    let gisInited = false

    gapiLoaded()
    gisLoaded()
    handleAuthClick()

    function gapiLoaded() {
      gapi.load('client:picker', initializePicker)
    }

    async function initializePicker() {
      await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
      pickerInited = true
    }

    function gisLoaded() {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '',
      })
      gisInited = true
    }

    function handleAuthClick() {
      tokenClient.callback = async response => {
        if (response.error !== undefined) {
          throw response
        }
        accessToken = response.access_token
        localStorage.setItem('google-drive-access-token', response.access_token)

        await createPicker()
      }

      if (accessToken === null) {
        tokenClient.requestAccessToken({ prompt: 'consent' })
      } else {
        tokenClient.requestAccessToken({ prompt: '' })
      }
    }

    function createPicker() {
      const view = new google.picker.View(google.picker.ViewId.DOCS)

      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setDeveloperKey(API_KEY)
        // .setAppId(APP_ID)
        .setOAuthToken(accessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setCallback(pickerCallback)
        .build()
      picker.setVisible(true)
    }

    async function pickerCallback(data) {
      if (data.action === google.picker.Action.PICKED) {
        const preparedData = await data.docs.map(doc => {
          return {
            name: doc.name,
            webViewLink: doc.url,
            iconLink: doc.iconUrl,
            mimeType: doc.mimeType,
          }
        })

        dispatchChange(preparedData)
      }
    }

    const dispatchChange = preparedData => {
      this.dispatchEvent(new CustomEvent('google-drive-url', { detail: preparedData }))
    }
  }

  disconnectedCallback() {
    window._elmApp.ports.googlePickerApiEvents.unsubscribe(this.onEvent)
  }

  onEvent = ev => {
    this.init()
  }
}

customElements.define('google-picker-api', GooglePickerComponent)
