/*********************************************************************

  PortFunnel.js

 *********************************************************************

  PortFunnel is the single global variable defined by this file.
  It is an object with a `subscribe` property, a function, called as:

  PortFunnel.subscribe(app)

  The `modules` property is a list of strings, each of which should
  correspond to the 'moduleName' set by one of your PortFunnel-aware
  JavaScript files.

  When each `module` JavaScript file is loaded.  It should set
  `PortFunnel.modules['moduleName']`, as illustrated in
  `PortFunnel/LocalStorage.js`,so that it can be hooked in to the
  funnelling mechanism below.

 *********************************************************************/

let PortFunnel = {
  registerModule,
  subscribe,
  modules: {}, // modules[funnelName].cmd set by module JS.
  sub: null,
}

function registerModule(module) {
  module(PortFunnel)
}

function subscribe(app) {
  PortFunnel.sub = app.ports.subPort

  app.ports.cmdPort.subscribe(function (command) {
    let returnValue = commandDispatch(command)

    if (returnValue) {
      PortFunnel.sub.send({ id: command.id, msg: returnValue })
    }
  })
}

function commandDispatch(command) {
  let msgId = command.id
  let moduleName = command.msg.module
  let module = PortFunnel.modules[moduleName]

  if (module) {
    let cmd = module.cmd

    if (cmd && !queue[moduleName]) {
      let tag = command.msg.tag
      let args = command.msg.args

      return cmd(tag, args)
    } else {
      let list = queue[moduleName]

      if (!list) list = []

      list.push(command)
      queue[moduleName] = list

      if (!queueDrainOutstanding) {
        scheduleQueueDrain()
      }
    }
  }
}

// queue[moduleName] = an array of commands passed to commandDispatch
// before the JavaScript module was installed.
let queue = {}
let queueDrainOutstanding = false

function scheduleQueueDrain() {
  queueDrainOutStanding = true
  setTimeout(drainQueue, 10) // is 0.01 second too short?
}

function drainQueue() {
  needReschedule = false

  for (let moduleName in queue) {
    let module = PortFunnel.modules[moduleName]

    if (!module) {
      // Can't happen, but handle it anyway
      delete queue[moduleName]
    } else {
      if (!module.cmd) {
        needReschedule = true
      } else {
        let list = queue[moduleName]

        delete queue[moduleName]

        for (let i in list) {
          let command = list[i]
          let returnValue = commandDispatch(command)

          if (returnValue) {
            PortFunnel.sub.send(returnValue)
          }
        }
      }

      if (needReschedule) {
        scheduleQueueDrain()
      } else {
        queueDrainOutstanding = false
      }
    }
  }
}

export default PortFunnel
