import { createPopper } from '@popperjs/core'

export class PopperContainer extends HTMLElement {
  static observedAttributes = [
    'data-placement',
    'data-strategy',
    'data-options',
    'data-condition',
    'data-tooltip-hidden',
  ]

  connectedCallback() {
    requestAnimationFrame(() => this.setup())
  }

  disconnectedCallback() {
    this.teardown()
  }

  attributeChangedCallback(name, oldVal, newVal) {
    if (oldVal !== newVal) {
      this.teardown()
      this.setup()
    }
  }

  teardown() {
    if (this.poll) {
      clearTimeout(this.poll)
    }

    if (this.popper) {
      this.popper.destroy()
    }
  }

  measureText(condition, tooltipElement) {
    const data = JSON.parse(condition)
    const element = document.createElement('div')

    element.style.position = 'absolute'
    element.style.visibility = 'hidden'
    element.style.whiteSpace = 'nowrap'
    element.className = data.classes
    element.innerHTML = data.text

    document.body.appendChild(element)

    const width = element.offsetWidth

    document.body.removeChild(element)

    return width > data.threshold
  }

  requestHide(tooltipElement) {
    tooltipElement.removeAttribute('data-show')
    this.popper.update()
  }

  setup() {
    const tooltipElementId = this.getAttribute('data-tooltip-id')
    const tooltipElement = tooltipElementId ? document.getElementById(tooltipElementId) : null
    const annotatedElement = this.firstElementChild

    if (annotatedElement) {
      if (tooltipElement) {
        const condition = this.getAttribute('data-condition')
        const hidden = this.getAttribute('data-tooltip-hidden')

        if (
          hidden !== '1' &&
          (condition == 'always' || this.measureText(condition, tooltipElement))
        ) {
          this.popper = createPopper(annotatedElement, tooltipElement, {
            placement: this.getAttribute('data-placement'),
            strategy: this.getAttribute('data-strategy'),
            modifiers: JSON.parse(this.getAttribute('data-modifiers')),
          })

          const requestShow = e => {
            tooltipElement.setAttribute('data-show', 'true')
            this.popper.update()
          }
          const closeClicked = e => {
            tooltipElement.removeAttribute('data-show')
            this.popper.update()
          }
          const closeElement = tooltipElement.querySelector('[data-tooltip-close]')

          if (closeElement) {
            closeElement.addEventListener('click', closeClicked)
          }

          annotatedElement.addEventListener('mouseenter', requestShow)
          annotatedElement.addEventListener('focus', requestShow)
          annotatedElement.addEventListener(
            'mouseleave',
            this.requestHide.bind(this, tooltipElement)
          )
          annotatedElement.addEventListener('blur', closeClicked)

          this.teardown = () => {
            this.requestHide(tooltipElement)

            if (closeElement) {
              closeElement.removeEventListener('click', closeClicked)
            }

            annotatedElement.removeEventListener('mouseenter', requestShow)
            annotatedElement.removeEventListener('focus', requestShow)
            annotatedElement.removeEventListener(
              'mouseleave',
              this.requestHide.bind(this, tooltipElement)
            )
            annotatedElement.removeEventListener('blur', closeClicked)
          }
        }
      } else {
        // If the tooltip element is not yet available, try again in 100ms
        this.poll = setTimeout(() => {
          this.setup()
          this.poll = null
        }, 100)
      }
    }
  }
}

window.customElements.define('popper-container', PopperContainer)
